import { Injectable } from '@angular/core';

import { LocalStorageService } from 'ngx-webstorage';
import { Observable, Subject } from 'rxjs';

import { UserFullInfoDto } from '../user/user-full-info-dto';

@Injectable()
export class CurrentUserService {
  watchStorage: Observable<boolean>;
  private readonly storageStatus = new Subject<boolean>();

  constructor(
    protected storage: LocalStorageService,
  ) {
    this.watchStorage = this.storageStatus.asObservable();
  }

  getItem(): UserFullInfoDto | null {
    const current = localStorage.getItem('currentuser');

    return current ? new UserFullInfoDto(JSON.parse(current)) : null;
  }

  isCurrentUserPermissionsExist(): boolean {
    const currentUser = localStorage.getItem('currentuser');

    return !!(currentUser && JSON.parse(currentUser)?.permissions);
  }

  removeItem(): void {
    localStorage.removeItem('currentuser');
    this.storageStatus.next(false);
  }

  setItem(currentUser: UserFullInfoDto): void {
    localStorage.setItem('currentuser', JSON.stringify(currentUser));
    this.storageStatus.next(true);
  }

  unsubscribe(): void {
    this.storageStatus.complete();
  }

  // watchStorage(): Observable<boolean> {
  //   return this.storageStatus.asObservable();
  // }
}
