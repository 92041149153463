import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { switchMap, tap } from 'rxjs/operators';

import { NewsSearchParamsDto } from '../_shared/dtos/news-search-params.dto';
import { News } from '../_shared/dtos/news.dto';
import { NewsTypeEnum } from '../_shared/enums/news.emun';
import { NewsService } from '../_shared/services/news/news.service';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.html',
  styleUrls: [ './news-feed.scss' ],
})
export class NewsFeedComponent implements OnInit {
  readonly newsTypeEnum = NewsTypeEnum;

  defaultParams = {
    isDeleted: false,
    isDraft: false,
    to: formatDate(new Date(), 'yyyy-MM-dd 23:59:59', 'en'),
    isPreviewMode: true,
  };
  isLoading: boolean;
  newsList$: Observable<News[]>;
  newsType: NewsTypeEnum | 'ALL' = 'ALL';

  private readonly _querySubj = new BehaviorSubject<NewsSearchParamsDto>({} as NewsSearchParamsDto);

  constructor(private readonly newsService: NewsService) {}

  ngOnInit(): void {
    this.newsList$ = this._querySubj.asObservable()
      .pipe(
        tap(() => {
          this.isLoading = true;
        }),
        switchMap(params =>  this.newsService.getAllNews(params)),
        tap(() => {
          this.isLoading = false;
        }),
      );
    this.changeParams(this.defaultParams);
  }

  changeParams(params: NewsSearchParamsDto): void {
    this._querySubj.next({
      ...this.defaultParams,
      ...params,
    });
  }

  getNewsTypeParams(newsType: NewsTypeEnum | 'ALL'): NewsSearchParamsDto {
    if (newsType === 'ALL') {

      return {} as NewsSearchParamsDto;
    }

    return { isReleaseNotes: newsType === NewsTypeEnum.RELEASE_NOTES };
  }
}
