import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SpinnerCircularSplitComponent } from './spinner-circular-split.component';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ SpinnerCircularSplitComponent ],
  exports: [ SpinnerCircularSplitComponent ],
})
export class SpinnerCircularSplitModule {}
