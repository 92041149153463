import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { ToastrModule, ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';

import { AuthService } from '../auth/auth.service';

import { CurrentUserService } from '../current-user/current-user.service';

import { AuthorizeRestService } from './authorize/authorize.service';
import { RestClient } from './rest.service';

@NgModule({
  imports: [
    HttpClientModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    RestClient,
    AuthService,
    AuthorizeRestService,
    CurrentUserService,
    ToastrService,
    LocalStorageService,
  ],
})
export class RestModule {}
