import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';

import { LoadingSpinnerModule } from './_shared/components/loading-spinner/loading-spinner.module';
import { PermissionsModule } from './_shared/modules/permissions/permissions.module';
import { StorageModule } from './_shared/modules/storage/storage.module';
import { AuthGuard } from './_shared/services/auth/auth.guard';
import { AuthService } from './_shared/services/auth/auth.service';
import { LogoutGuard } from './_shared/services/auth/logout.guard';
import {
  CustomIconsRegistrationService,
} from './_shared/services/custom-icons-registaration/custom-icons-registration.service';
import { RestInterceptor } from './_shared/services/rest/rest.interceptor';
import { RestModule } from './_shared/services/rest/rest.module';
import { RestClient } from './_shared/services/rest/rest.service';

import { NewsFormStateService } from './admin-panel/manage-news-feed/news-form-state.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { HeaderToolbarModule } from './header-toolbar/header-toolbar.module';
import { SidebarStateService } from './sidebar/sidebar-state.service';
import { SidebarModule } from './sidebar/sidebar.module';

const customIconsRegistrationInitializerFactory = (customIconsRegistration: CustomIconsRegistrationService) => () => {
  customIconsRegistration.registerIcons();
};

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexModule,
    HeaderToolbarModule,
    LoadingSpinnerModule,
    MatIconModule,
    MatSidenavModule,
    PermissionsModule,
    RestModule,
    SidebarModule,
    StorageModule,
    NoopAnimationsModule,
  ],
  bootstrap: [ AppComponent ],
  declarations: [ AppComponent ],
  providers: [
    AuthService,
    AuthGuard,
    CustomIconsRegistrationService,
    LogoutGuard,
    RestClient,
    NewsFormStateService,
    SidebarStateService,
    {
      provide: APP_INITIALIZER,
      useFactory: customIconsRegistrationInitializerFactory,
      multi: true,
      deps: [CustomIconsRegistrationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RestInterceptor,
      multi: true,
    },
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: false,
    },
  ],
})
export class AppModule {}
