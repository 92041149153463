import { NgModule } from '@angular/core';

import { NgxWebstorageModule } from 'ngx-webstorage';

@NgModule({
  imports: [
    NgxWebstorageModule.forRoot({
      prefix: '',
      separator: '',
      caseSensitive: true,
    }),
  ],
  exports: [ NgxWebstorageModule ],
})
export class StorageModule {}
