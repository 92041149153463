import { Injectable, Input } from '@angular/core';

@Injectable()
export class SpinnerComponent {
  @Input() color = '#FC8302';
  @Input() enabled = true;
  @Input() size: number | string = 100;
  @Input() speed = 100;
  @Input() still = false;
  @Input() styles = {};
  @Input() thickness = 100;

  get svgStyle(): Record<string, string> {
    return {
      color: this.color,
      overflow: 'visible',
      width: normalizeSize(this.size),
      height: normalizeSize(this.size),
      ...typeof this.styles === 'string' ? JSON.parse(this.styles) : this.styles,
    } as Record<string, string>;
  }
}

@Injectable()
export class SpinnerComponentWithSecondaryColor extends SpinnerComponent {
  @Input() secondaryColor = 'rgba(255, 255, 255, 1)';
}

export const normalizeSize = (size: number | string): string =>
  parseFloat(size.toString()).toString() === size.toString()
    ? `${size}px`
    : size.toString();
