<div
  class="navigation"
  fxLayout
  fxLayoutAlign=" center">

  <span
    class="nav-element cursor-pointer"
    [routerLink]="''">
    <b>SPD portal</b>
  </span>

  <mat-icon
    class="mx-2 size-5"
    fxLayout
    fxLayoutAlign=" center"
    svgIcon="arrow-right">
  </mat-icon>

  <span class="nav-element active-element">
    News Feed
  </span>
</div>

<div class="title mb-4">
  News Feed
</div>

<ng-container *ngIf="newsList$ | async as list">
  <div class="header-filters mb-4">
    <mat-form-field appearance="fill">
      <mat-label>News Type</mat-label>

      <mat-select
        class="header-select"
        [(ngModel)]="newsType"
        [ngModelOptions]="{ standalone: true }"
        (selectionChange)="changeParams(getNewsTypeParams(newsType))">

        <mat-option [value]="'ALL'">All Types</mat-option>

        <mat-option
          *ngFor="let option of newsTypeEnum | keyvalue"
          [value]="option.value">

          {{ option.value | normalizeEnum }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <app-news-list-preview
    *ngIf="list.length; else loadingData"
    [newsList]="list">
  </app-news-list-preview>

  <ng-template
    *ngIf="isLoading; else emptyNewsList"
    #loadingData>

    <div
      class="main-container w-100"
      fxFlexFill
      fxLayout="column"
      fxLayoutAlign="space-around center">

      <app-spinner-circular-split></app-spinner-circular-split>
    </div>
  </ng-template>

  <ng-template #emptyNewsList>
    <div
      *ngIf="!list.length"
      class="mt-4"
      fxLayout
      fxLayoutAlign="center">

      There are no {{ newsType === 'ALL' ? 'Articles' : (newsType | normalizeEnum) }} for now...
    </div>
  </ng-template>
</ng-container>
