<div
  class="container"
  [class.bottom-border]="isBottomMarker"
  [class]="color">

  <div
    class="status-label"
    fxLayout
    fxLayoutAlign="center center">

    new
  </div>

  <div class="mt-2">
    {{ text }}
  </div>

  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>
