import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.html',
  styleUrls: [ './popup.scss' ],
})
export class PopupComponent {
  @Input() color = 'orange';
  @Input() content: TemplateRef<unknown>;
  @Input() isBottomMarker = true;
  @Input() text: string;
}
