import { Injectable } from '@angular/core';

import { PermissionsEnum } from '../../enums/permissions/permissions.enum';

import { CurrentUserService } from '../../services/current-user/current-user.service';

@Injectable()
export class PermissionsService {
  private userRoles: PermissionsEnum[] | string[] = [];

  constructor(
    private readonly userService: CurrentUserService,
  ) {}

  isAllowed(targetPermissions: PermissionsEnum[] = [], haveToIncludeAllPermissions = false): boolean {
    this.userRoles = this.userService.getItem()?.permissions;

    if (!targetPermissions.length) {
      return true;
    }

    if (haveToIncludeAllPermissions) {
      return this.includesAllUserPermissions(targetPermissions);
    } else {
      return this.includesOneUserPermissions(targetPermissions);
    }
  }

  private findUserPermission(userRole: PermissionsEnum, userPermission: PermissionsEnum, permissions: PermissionsEnum[]): PermissionsEnum {
    return permissions.find(
      permission =>
        permission.constructor === Array
          ? !!Array.from(permission).every((permItem: PermissionsEnum) => userRole === permItem)
          : permission === userPermission,
    );
  }

  private findUserRole(userRole: PermissionsEnum, permissions: PermissionsEnum[]): PermissionsEnum {
    return [userRole].find(
      userPermission => this.findUserPermission(userRole, userPermission, permissions),
    );
  }

  private includesAllUserPermissions(targetPermissions: PermissionsEnum[]): boolean {
    return !!targetPermissions.every(
      targetPermission => this.includesOneUserPermissions([targetPermission]),
    );
  }

  private includesOneUserPermissions(targetPermissions: PermissionsEnum[]): boolean {
    return !!this.userRoles?.find(
      userRole => this.findUserRole(userRole, targetPermissions),
    );
  }
}
