import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { MicroservicesEnum } from '../../enums/microservices.enum';
import { TokenTypesEnum } from '../../enums/token-types.enum';

import { CurrentUserService } from '../current-user/current-user.service';
import { UserFullInfoDto } from '../user/user-full-info-dto';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly currentUserService: CurrentUserService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const current: UserFullInfoDto = this.currentUserService.getItem();

    if (Object
      .keys(MicroservicesEnum)
      .every(microService => this.authService.isLoggedIn(MicroservicesEnum[ microService ], TokenTypesEnum.refreshToken))
      && current
      || !!state.root.queryParams[ 'auth' ]) {

      return true;
    }

    this.router.navigate(['login']);

    return false;
  }
}
