import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { RELEASES_PROMOTION_DATES } from '../_shared/constants/releases-promotion-dates';
import { ICONS_SVG } from '../_shared/constants/svg-images.const';
import { MicroservicesEnum } from '../_shared/enums/microservices.enum';
import { PermissionsEnum } from '../_shared/enums/permissions/permissions.enum';
import { TokenTypesEnum } from '../_shared/enums/token-types.enum';
import { AuthService } from '../_shared/services/auth/auth.service';
import { CurrentUserService } from '../_shared/services/current-user/current-user.service';
import { UserFullInfoDto } from '../_shared/services/user/user-full-info-dto';

@Component({
  selector: 'app-header-toolbar',
  templateUrl: './header-toolbar.html',
  styleUrls: [ './header-toolbar.scss' ],
})
export class HeaderToolbarComponent implements OnInit, OnDestroy {
  @Input() currentUser: UserFullInfoDto;
  @Input() isSidenavOpened: boolean;

  @Output() sidenavOpenedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentDay = new Date();
  permissionsEnum = PermissionsEnum;
  releasesPromotionDates = RELEASES_PROMOTION_DATES;

  constructor(
    private readonly overlayContainer: OverlayContainer,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly authService: AuthService,
    private readonly currentUserService: CurrentUserService,
    private readonly router: Router,
  ) {
    this.matIconRegistry.addSvgIcon(
      'user',
      this.domSanitizer.bypassSecurityTrustResourceUrl(ICONS_SVG['user']),
    );
  }

  ngOnInit(): void {
    const currentUser = this.currentUserService.getItem();

    if (currentUser?.id) {
      this.currentUser = currentUser;
    }

    this.currentUserService.watchStorage.subscribe(data => {
      if (data) {
        this.currentUser = this.currentUserService.getItem();
      }
    });
  }

  ngOnDestroy(): void {
    this.authService.unsubscribe();
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  logout(): void {
    this.currentUserService.removeItem();
    this.authService.resetAuthorization(MicroservicesEnum.TT_NEW, TokenTypesEnum.accessToken, true);
    this.sidenavOpenedChange.emit(false);
    this.router.navigate(['/logout']);
  }

  redirectTo(route: string): void {
    this.router.navigate([ route ]);
  }
}
