export class UserMinDto {

  email: string;
  firstName: string;

  get fullName(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }

  id: number;
  lastName: string;
  profileUrl: string;
  selected?: boolean;

  constructor(data?: UserMinDto) {
    if (data) {
      this.email = data.email;
      this.firstName = data.firstName;
      this.id = data.id;
      this.lastName = data.lastName;
      this.profileUrl = data.profileUrl;
      this.selected = data.selected;
    }
  }
}
