import { Injectable } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

import { NewsFormConfig } from './configs/news-form.config';

@Injectable()
export class NewsFormStateService {
  private readonly _newsFormStateSubject: BehaviorSubject<FormGroup>;

  constructor(private readonly newsFormConfig: NewsFormConfig) {
    this._newsFormStateSubject = new BehaviorSubject<FormGroup>(this.newsFormConfig.publishNewsForm());
  }

  getNewsForm(): FormGroup {
    return this._newsFormStateSubject.getValue();
  }

  setNewsFormState(value: FormGroup): void {
    this._newsFormStateSubject.next(value);

  }

  unsubscribe(): void {
    this._newsFormStateSubject.complete();
  }

  watchOnNewsFormState(): Observable<FormGroup> {
    return this._newsFormStateSubject.asObservable();
  }
}
