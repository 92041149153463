<ng-container *ngIf="newsList">
  <div
    class="m-0"
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    fxLayoutGap="1rem grid"
    fxLayoutGap.xs="0.5rem grid">

    <div
      *ngFor="let item of newsList"
      class="news-list"
      fxFlex.lt-sm="100%"
      [fxFlex.gt-lg]="isSidenavOpened ? '100%' : '50%'"
      [fxFlex]="isSidenavOpened ? '100%' : '50%'"
      [class.pr-0]="isSidenavOpened">

      <mat-card
        *ngIf="item.article"
        class="article-preview"
        fxLayout="column"
        fxLayoutAlign="space-between"
        title="Click to open details"
        (click)="openDetails(item.id)"
        [ngClass]="isSidenavOpened ? 'mh-xs' : 'mh-l'">

        <div fxLayout="column">
          <img
            class="w-100"
            alt="article"
            [src]="item.article.header">

          <div
            class="title-content mt-2"
            [title]="item.article.title">

            {{ item.article.title }}
          </div>

          <div
            class="subtitle-content mt-1"
            fxLayout="column">

            <span *ngIf="item.article.subTitle">
              {{ item.article.subTitle }}
            </span>

            <div
              fxLayout
              fxLayoutAlign="space-between center">

              <span>Published {{ item.publishDate | date : 'dd.MM.yyyy' }}</span>

              <div
                *ngIf="!item.views.includes(currentUser?.id)"
                class="status-label color-inactive ml-2"
                fxLayout
                fxLayoutAlign="center center">

                unread
              </div>

              <mat-icon
                *ngIf="item.isPinned"
                class="h-18"
                fxLayout
                fxLayoutAlign="center center"
                svgIcon="pushpin-icon2">
              </mat-icon>
            </div>
          </div>

          <div class="preview-content mt-4">
            {{ item.article.previewContent }}
          </div>
        </div>

          <div
            class="view-content mt-2"
            fxLayout
            fxLayoutAlign=" center">

            <div
              class="type-label mt-1"
              fxLayout
              fxLayoutAlign="center center"
              [ngClass]="item.isReleaseNotes ? 'color-release-notes' : 'color-news'">

              {{ (item.isReleaseNotes ? newsTypeEnum.RELEASE_NOTES : newsTypeEnum.NEWS) | normalizeEnum | lowercase }}
            </div>

            <mat-icon
              class="view-icon ml-3 mt-1"
              matTooltip="Number of views"
              fxLayout
              fxLayoutAlign=" center"
              svgIcon="show-icon">
            </mat-icon>

            <div
              class="subtitle-content mw-24 mt-1"
              fxLayout
              fxLayoutAlign=" center">

              {{ item.views?.length || 0 }}
            </div>

            <mat-icon
              class="reaction-icon ml-1"
              matTooltip="Likes"
              fxLayout
              fxLayoutAlign="center center"
              svgIcon="like-icon">
            </mat-icon>

            <div
              class="subtitle-content mw-24 mt-1 ml-2"
              fxLayout
              fxLayoutAlign=" center">

              {{ item.likes?.length || 0 }}
            </div>

            <mat-icon
              class="reaction-icon ml-1 mt-2"
              mat-icon-button
              matTooltip="Dislikes"
              fxLayout
              fxLayoutAlign="center center"
              svgIcon="dislike-icon">
            </mat-icon>

            <div
              class="subtitle-content mw-24 mt-1 ml-2"
              fxLayout
              fxLayoutAlign=" center">

              {{ item.dislikes?.length || 0 }}
            </div>
          </div>
      </mat-card>
    </div>
  </div>
</ng-container>
