import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { NewFeatureMarkerComponent } from './new-feature-marker.component';

@NgModule({
  declarations: [ NewFeatureMarkerComponent ],
  exports: [ NewFeatureMarkerComponent ],
  imports: [ MatIconModule ],
})
export class NewFeatureMarkerModule {}
