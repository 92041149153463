import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, timeout } from 'rxjs/operators';

import { MicroservicesEnum } from '../../enums/microservices.enum';
import { TokenTypesEnum } from '../../enums/token-types.enum';
import { AuthService } from '../auth/auth.service';

import { CurrentUserService } from '../current-user/current-user.service';

import { spdInfoUsersRestService } from '../user/spd-info/users.service';
import { UserFullInfoDto } from '../user/user-full-info-dto';

import { environment } from '@config/environment';

import { AuthorizeRestService } from './authorize/authorize.service';

@Injectable()
export class RestInterceptor implements HttpInterceptor {

  defaultRequestTimeout = 300000;
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private readonly injector: Injector,
    private readonly authService: AuthService,
    private readonly currentUserService: CurrentUserService,
    private readonly spdInfoUserService: spdInfoUsersRestService,
    private readonly router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone(this.getRequestParams(request));

    return next.handle(request)
      .pipe(
        timeout(this.defaultRequestTimeout),
        catchError(error => {
          if (error.status === 401) {
            return this.initAuthService(request, next);
          }

          return throwError(error);
        }));
  }

  private getContentTypeHeader(request): Record<string, string> {
    if (!(request.body instanceof FormData)) {
      return {
        'Content-Type': 'application/json',
      };
    }
  }

  private getRequestParams(request: HttpRequest<unknown>): Record<string, unknown> {
    return {
      url: `${environment.api}${String(request.url)}`,
      setHeaders: {
        Authorization: this.initAuthorizationHeader(request.url),
        ...this.getContentTypeHeader(request),
      },
    };
  }

  private getRequestParamsAfterRefresh(request: HttpRequest<unknown>): Record<string, unknown> {
    return {
      url: `${String(request.url)}`,
      setHeaders: {
        Authorization: this.initAuthorizationHeader(request.url),
        ...this.getContentTypeHeader(request),
      },
    };
  }

  private initAuthorizationHeader(requestUrl: string): string {
    const oauthPath = '/oauth/token';
    const oauthRequest = requestUrl.includes(oauthPath);
    const serviceToken = this.authService.getToken(Object.values(MicroservicesEnum).find(elem => requestUrl.includes(elem)));

    return !serviceToken || oauthRequest ? environment.oauthHeader : `Bearer ${serviceToken}`;
  }

  private initAuthService(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const refreshTokenSpdInfo = this.authService.getToken(MicroservicesEnum.SPD_INFO, TokenTypesEnum.refreshToken);
    const refreshTokenTTNew = this.authService.getToken(MicroservicesEnum.TT_NEW, TokenTypesEnum.refreshToken);

    if (!refreshTokenSpdInfo && !refreshTokenTTNew) {
      this.navigateToRedirect();
    } else {
      if (!this.isRefreshingToken) {
        this.isRefreshingToken = true;
        this.tokenSubject.next(null);
        const auth = this.injector.get(AuthorizeRestService);

        return auth.userTokenRefresh(refreshTokenTTNew).pipe(
          switchMap(authorize => forkJoin([ of(authorize), auth.userTokenSpdInfoRefresh(refreshTokenSpdInfo) ])),
          switchMap(([ authorizeTimeTracker, authorizeSpdInfo ]) => {
            this.authService.setToken(authorizeTimeTracker.accessToken, MicroservicesEnum.TT_NEW);
            this.authService.setToken(authorizeTimeTracker.refreshToken, MicroservicesEnum.TT_NEW, TokenTypesEnum.refreshToken);
            this.authService.setToken(authorizeSpdInfo.access_token, MicroservicesEnum.SPD_INFO);
            this.authService.setToken(authorizeSpdInfo.refresh_token, MicroservicesEnum.SPD_INFO, TokenTypesEnum.refreshToken);

            return forkJoin([of(authorizeTimeTracker), this.spdInfoUserService.getCurrentUser()]);
          }),
          switchMap(([authorizeTimeTracker, user]) => {

            this.currentUserService.setItem(new UserFullInfoDto(user));
            this.tokenSubject.next(authorizeTimeTracker.accessToken);
            request = request.clone(this.getRequestParamsAfterRefresh(request));

            return next.handle(request);
          }),
          // @ts-ignore
          catchError(() => {
            this.navigateToRedirect();
          }),
          finalize(() => {
            this.isRefreshingToken = false;
          }),
        );
      } else {
        return this.tokenSubject
          .pipe(
            filter(token => token != null),
            take(1),
            switchMap(() => {
              request = request.clone(this.getRequestParamsAfterRefresh(request));

              return next.handle(request);
            }),
          );
      }
    }
  }

  private navigateToRedirect(): void {
    Object.values(MicroservicesEnum).map(key => {
      this.authService.resetAuthorization(key, TokenTypesEnum.accessToken);
      this.authService.resetAuthorization(key, TokenTypesEnum.refreshToken);
    });
    this.currentUserService.removeItem();
    this.router.navigate([ './login' ]);
  }
}
