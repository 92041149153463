import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Article } from '../../../_shared/dtos/article.dto';
import { News } from '../../../_shared/dtos/news.dto';

@Injectable({
  providedIn: 'root',
})
export class NewsFormConfig {
  fb: FormBuilder = new FormBuilder();

  publishArticleForm(article?: Article): FormGroup {
    return this.fb.group({
      id: [ { value: article?.id, disabled: !article?.id } ],
      header: [ article?.header || '', Validators.required ],
      title: [ article?.title || '', Validators.required ],
      subTitle: [ article?.subTitle || '' ],
      content: [ article?.content || '', Validators.required ],
      footer: [ article?.footer || '' ],
      previewContent: [ article?.previewContent || '', Validators.required ],
    });
  }

  publishNewsForm(news?: News): FormGroup {
    return this.fb.group({
      id: [ { value: news?.id, disabled: !news?.id } ],
      description: [ news?.description || '' ],
      article: this.publishArticleForm(news?.article || {} as Article),
      createdBy: [ news?.createdBy || null, Validators.required ],
      dislikes: [ news?.dislikes || [] ],
      isDeleted: [ news?.isDeleted || false, Validators.required ],
      isDraft: [ news?.isDraft || false, Validators.required ],
      isPinned: [ news?.isPinned || false, Validators.required ],
      isReleaseNotes: [ news?.isReleaseNotes || false, Validators.required ],
      likes: [ news?.likes || [] ],
      publishDate: [ news?.publishDate || null, Validators.required ],
      updatedBy: [ news?.updatedBy || null ],
      views: [ news?.views || [] ],
    });
  }
}
