export enum NewsTypeEnum {
  NEWS = 'NEWS',
  RELEASE_NOTES = 'RELEASE_NOTES',
}

export enum NewsStatusEnum {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
  SCHEDULED = 'SCHEDULED',
}

export enum NewsActionsEnum {
  EDIT = 'EDIT',
  INACTIVATE = 'INACTIVATE',
  PIN = 'PIN',
  RESTORE = 'RESTORE',
}
