import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'normalizeEnum',
})
export class NormalizeEnumPipe implements PipeTransform {

  transform(value: string | unknown): string {
    if (!value) {
      return '';
    }
    const arr = String(value).split('').map(letter => letter === '_' ? ' ' : letter.toLowerCase()).join('');

    return arr.charAt(0).toUpperCase() + arr.slice(1);
  }
}
