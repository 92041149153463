import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SafeUrlModule } from '../_shared/pipes/safe-url/safe-url.module';
import { NewsFeedModule } from '../news-feed/news-feed.module';
import { NewsPreviewModule } from '../news-feed/news-preview/news-preview.module';

import { SidebarComponent } from './sidebar.component';

@NgModule({
  declarations: [ SidebarComponent ],
  exports: [ SidebarComponent ],
  imports: [
    CommonModule,
    ExtendedModule,
    FlexModule,
    MatIconModule,
    MatTooltipModule,
    SafeUrlModule,
    NewsFeedModule,
    NewsPreviewModule,
  ],
})
export class SidebarModule {}
