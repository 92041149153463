import { Injectable } from '@angular/core';

import { LocalStorageService } from 'ngx-webstorage';
import { Observable, Subject } from 'rxjs';

import { MicroservicesEnum } from '../../enums/microservices.enum';
import { TokenTypesEnum } from '../../enums/token-types.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly storageStatus = new Subject<string>();

  constructor(
    protected storage: LocalStorageService,
  ) {}

  static concatToken(service: MicroservicesEnum, type = TokenTypesEnum.accessToken): string {
    return `${service}-${type}`;
  }

  getToken(service: MicroservicesEnum, type?: TokenTypesEnum): string | null {
    return localStorage.getItem(AuthService.concatToken(service, type))
      ? String(JSON.parse(localStorage.getItem(AuthService.concatToken(service, type))))
      : null;
  }

  isCurrentUserPermissionsExist(): boolean {
    const currentUser = localStorage.getItem('currentuser');

    return !!(currentUser && JSON.parse(currentUser)?.permissions);
  }

  isLoggedIn(service: MicroservicesEnum, type?: TokenTypesEnum): boolean {
    return !!localStorage.getItem(AuthService.concatToken(service, type)) && this.isCurrentUserPermissionsExist();
  }

  resetAuthorization(service: MicroservicesEnum, type?: TokenTypesEnum, clearAll = false): void {
    const favouriteList = localStorage.getItem('favouritelist');

    if (!clearAll) {
      localStorage.removeItem(AuthService.concatToken(service, type));
    } else {
      localStorage.clear();
    }
    localStorage.setItem('favouritelist', favouriteList);
    this.storageStatus.next('removed');
  }

  setToken(token: string, service: MicroservicesEnum, type?: TokenTypesEnum): void {
    localStorage.setItem(AuthService.concatToken(service, type), JSON.stringify(token));
    this.storageStatus.next('added');
  }

  unsubscribe(): void {
    this.storageStatus.complete();
  }

  watchStorage(): Observable<string> {
    return this.storageStatus.asObservable();
  }
}
