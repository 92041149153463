import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FlexModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';

import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NormalizeEnumModule } from '../../_shared/pipes/normalize-enum/normalize-enum.module';

import { NewsPreviewComponent } from './news-preview.component';

@NgModule({
  declarations: [ NewsPreviewComponent ],
  imports: [
    CommonModule,
    FlexModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    NormalizeEnumModule,
  ],
  exports: [ NewsPreviewComponent ],
})
export class NewsPreviewModule {}
