export const ICONS_SVG = {
  accountsIcon: 'accounts-icon',
  adminIcon: 'admin2-icon',
  arrowRight: 'arrow-right',
  bellIcon: 'bell-icon',
  bucketIcon: 'bucket-icon',
  calendarIcon: 'calendars-icon',
  bug: 'bug',
  lightBulb: 'light-bulb',
  chartIcon: 'chart-icon',
  confluence: 'confluence',
  contactsIcon: 'contacts-icon',
  dislikeIcon: 'dislike-icon',
  documentsIcon: 'documents-icon',
  editIcon: 'edit-icon',
  editIcon2: 'edit-icon2',
  fireIcon: 'fire-icon',
  flameIcon: 'flame-icon',
  gifIcon: 'gif-icon',
  hideIcon: 'hide-icon',
  hierarchy: 'hierarchy-icon',
  issuesIcon: 'issues-icon',
  jira: 'jira',
  jpegIcon: 'jpeg-icon',
  kebabMenu: 'kebab-menu-icon',
  likeIcon: 'like-icon',
  linkIcon: 'link-icon',
  manageData: 'manage-data',
  messageIcon: 'message-icon',
  orderingIcon: 'ordering-icon',
  palmIcon: 'palmier-soleil',
  pdfIcon: 'pdf-icon',
  pictureIcon: 'picture-icon',
  plusIcon: 'plus-icon',
  pngIcon: 'png-icon',
  portfolioIcon: 'portfolio2-icon',
  projectsIcon: 'projects-icon',
  pushpinIcon: 'pushpin-icon',
  pushpinIcon2: 'pushpin-icon2',
  restoreIcon: 'restore-icon',
  rightArrow: 'right-arrow',
  rocketIcon: 'rocket-icon',
  saveIcon: 'save-icon',
  showIcon: 'show-icon',
  starIcon: 'star2-icon',
  starOutlineIcon: 'star-icon',
  statisticsIcon: 'statistics2-icon',
  statusIcon: 'status-icon',
  svgIcon: 'svg-icon',
  trackTime: 'track-time',
  userPortal: 'portal-user',
  usersIcon: 'users-icon',
};
