import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AbstractIgnoreListOption } from './models/abstract-black-list-option.interface';

@Injectable()
export class LoaderService {
  private readonly isLoading = new BehaviorSubject<boolean>(false);
  private ignoreListOptions: AbstractIgnoreListOption[] = [];

  clearIgnoreListOptions(): void {
    this.ignoreListOptions = [];
  }

  getIgnoreListOptions(): AbstractIgnoreListOption[] {
    return this.ignoreListOptions;
  }

  getIsLoadingStatus(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  setIgnoreListOption(option: AbstractIgnoreListOption): void {
    this.ignoreListOptions = [...this.ignoreListOptions, option];
  }

  setIsLoadingStatus(status: boolean): void {
    this.isLoading.next(status);
  }
}
