export class PageableDto {
  page?: number;
  size?: number;
  sort?: string;

  constructor(data?: PageableDto) {
    if (data) {
      this.page = data.page;
      this.size = data.size;
      this.sort = data.sort;
    }
  }
}
