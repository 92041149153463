import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { MicroservicesEnum } from '../../../enums/microservices.enum';
import { RestClient } from '../../rest/rest.service';

import { UserFullInfoDto } from '../user-full-info-dto';

@Injectable({
  providedIn: 'root',
})
export class spdInfoUsersRestService {

  constructor(
    private readonly http: RestClient,
  ) {}

  getCurrentUser(): Observable<UserFullInfoDto> {
    return this.http.get<UserFullInfoDto>(`/${MicroservicesEnum.SPD_INFO}/api/v1/users/current`);
  }
}
