import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';

import { MicroservicesEnum } from '../../enums/microservices.enum';
import { TokenTypesEnum } from '../../enums/token-types.enum';
import { AuthService } from '../auth/auth.service';

import { environment } from '@config/environment';

@Injectable({
  providedIn: 'root',
  deps: [ AuthService ],
})
export class LoginUrlRedirectResolver implements Resolve<Observable<string>> {

  constructor(
    private readonly authService: AuthService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const queryParams = Object.entries(route.queryParams)
      .reduce(( acc, [key, value]) => [...acc, `${key}=${String(value)}`], [] as string[])
      .join('&');

    if (Object
      .keys(MicroservicesEnum)
      .every(microService => this.authService.isLoggedIn(MicroservicesEnum[ microService ], TokenTypesEnum.refreshToken))
      || !!state.root.queryParams[ 'auth' ]) {
      return of(window.location.href = `${environment.sourceUrl}${queryParams ? `?${queryParams}` : ''}`);
    } else {

      return of(window.location.href = `${String(route.data.loginUrl)}${queryParams ? `?${queryParams}` : ''}`);

    }
  }
}
