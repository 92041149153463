import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';

import {
  SpinnerCircularSplitModule,
} from '../_shared/components/spinner/spinner-circular-split/spinner-circular-split.module';
import { NormalizeEnumModule } from '../_shared/pipes/normalize-enum/normalize-enum.module';
import { NewsService } from '../_shared/services/news/news.service';

import { NewsFeedComponent } from './news-feed.component';
import { NewsFeedRouting } from './news-feed.routing';
import { NewsPreviewModule } from './news-preview/news-preview.module';

@NgModule({
  declarations: [ NewsFeedComponent ],
  imports: [
    CommonModule,
    NewsFeedRouting,
    FlexModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    NewsPreviewModule,
    NormalizeEnumModule,
    RouterModule,
    SpinnerCircularSplitModule,
  ],
  exports: [ NewsFeedComponent ],
  providers: [ NewsService ],
})
export class NewsFeedModule {}
