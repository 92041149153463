import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PermissionsDirective } from './permissions.directive';
import { PermissionsService } from './permissions.service';

@NgModule({
  imports: [ CommonModule ],
  providers: [ PermissionsService ],
  declarations: [ PermissionsDirective ],
  exports: [ PermissionsDirective ],
})
export class PermissionsModule {}
