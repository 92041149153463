import { PeriodDateDto } from './period-date.dto';

export class ProjectsDto {
  id: number;
  intervals: PeriodDateDto[] = [];
  name: string;

  constructor(data?: ProjectsDto) {
    if (data) {
      this.id = data.id;
      this.intervals = Array.isArray(data.intervals) ? data.intervals : [];
      this.name = data.name;
    }
  }
}
