import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { NewFeatureMarkerModule } from '../_shared/components/new-feature-marker/new-feature-marker.module';
import { PopupModule } from '../_shared/components/popup/popup.module';
import { PermissionsModule } from '../_shared/modules/permissions/permissions.module';
import { AuthService } from '../_shared/services/auth/auth.service';

import { HeaderToolbarComponent } from './header-toolbar.component';

@NgModule({
  declarations: [ HeaderToolbarComponent ],
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatTooltipModule,
    NewFeatureMarkerModule,
    PermissionsModule,
    PopupModule,
    RouterModule,
  ],
  exports: [ HeaderToolbarComponent ],
  providers: [ AuthService ],
})
export class HeaderToolbarModule {}
