import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NormalizeEnumPipe } from './normalize-enum.pipe';

@NgModule({
  declarations: [ NormalizeEnumPipe ],
  imports: [ CommonModule ],
  exports: [ NormalizeEnumPipe ],
})
export class NormalizeEnumModule {}
