import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FlexModule } from '@angular/flex-layout';

import { PopupComponent } from './popup.component';
import { PopupDirective } from './popup.directive';

@NgModule({
  declarations: [
    PopupComponent,
    PopupDirective,
  ],
  exports: [
    PopupComponent,
    PopupDirective,
  ],
  imports: [ CommonModule, FlexModule ],
})
export class PopupModule {}
