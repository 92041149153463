import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NewsSearchParamsDto } from '../../dtos/news-search-params.dto';
import { News } from '../../dtos/news.dto';
import { RestClient } from '../rest/rest.service';

@Injectable()
export class NewsService {

  constructor(private readonly http: RestClient) {}

  createNews(news: News): Observable<News> {
    return this.http.post<News>('/api/news', news);
  }

  getAllNews(params?: NewsSearchParamsDto): Observable<News[]> {
    return this.http.get<News[]>('/api/news', (params ?? {}) as HttpParams);
  }

  getNewsById(id: number, params?: NewsSearchParamsDto): Observable<News> {
    return this.http.get<News>(`/api/news/${id}`, (params ?? {}) as HttpParams);
  }

  updateNews(news: News): Observable<News> {
    return this.http.put<News>('/api/news', news);
  }
}
