export class PersonalConfigDto {

  defaultCalendarUsersFilters: string[];
  showYearOfBirth: boolean;

  constructor(data?: PersonalConfigDto) {
    if (data) {
      this.showYearOfBirth = data.showYearOfBirth;
      this.defaultCalendarUsersFilters = Array.isArray(data.defaultCalendarUsersFilters) ? data.defaultCalendarUsersFilters : [];
    }
  }
}
