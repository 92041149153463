import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { PermissionsEnum } from '../../enums/permissions/permissions.enum';

import { CurrentUserService } from '../../services/current-user/current-user.service';

import { PermissionsService } from './permissions.service';

class IPermissionsParams {
  permissions: PermissionsEnum[];
}

@Directive({
  selector: '[appPermissions]',
})
export class PermissionsDirective implements OnInit {
  @Input()
  set appPermissions(value: IPermissionsParams) {
    this.permissions = value.permissions;

    this.updateAfterUserChanges();
  }

  private hasView = false;
  private permissions: PermissionsEnum[] = [];

  constructor(
    private readonly userService: CurrentUserService,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef,
    private readonly permissionsService: PermissionsService,
  ) {}

  ngOnInit(): void {
    this.setVisibility();
  }

  private setVisibility(): void {
    const isAllowed = this.permissionsService.isAllowed(this.permissions);

    if (isAllowed && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isAllowed && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  private updateAfterUserChanges(): void {
    this.userService.watchStorage.subscribe(() => {
      this.setVisibility();
    });
  }
}
