<ng-container *ngIf="isLoading">

<div
  *ngIf="mode === 'spinner'"
  class="highcharts-gauge-loading-spinner">

  <mat-spinner></mat-spinner>
</div>

<div
  *ngIf="mode === 'bar'"
  class="highcharts-gauge-loading-bar">

  <mat-progress-bar
    mode="determinate"
    [value]="progress">
  </mat-progress-bar>
</div>
</ng-container>
