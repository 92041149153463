import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';

import { MicroservicesEnum } from '../../enums/microservices.enum';
import { TokenTypesEnum } from '../../enums/token-types.enum';
import { AuthService } from '../auth/auth.service';

import { environment } from '@config/environment';

@Injectable({
  providedIn: 'root',
  deps: [ AuthService ],
})
export class MediaUrlRedirectResolver implements Resolve<Observable<string>> {

  constructor(
    private readonly authService: AuthService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    if (Object
      .keys(MicroservicesEnum)
      .every(microService => this.authService.isLoggedIn(MicroservicesEnum[ microService ], TokenTypesEnum.refreshToken))) {
      return of(window.location.href = `${environment.sourceUrl}/media/${String(route.params['code'])}`);
    } else {
      return of(window.location.href = `${String(route.data.loginUrl)}`);
    }
  }
}
