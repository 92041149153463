import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

export interface ISidebarState {
  isSidebarOpened: boolean;
  newsIds: string[];
}

@Injectable()
export class SidebarStateService {
  state: ISidebarState = {
    isSidebarOpened: false,
    newsIds: [],
  };

  private readonly _sidebarStateSubject: BehaviorSubject<ISidebarState>;

  constructor() {
    this._sidebarStateSubject = new BehaviorSubject<ISidebarState>(this.state);
  }

  getSidebarState(): ISidebarState {
    return this._sidebarStateSubject.getValue();
  }

  setSidebarStateItem<K extends keyof ISidebarState>(key: K, value: ISidebarState[K]): void {
    this.state[key] = value;
    this.sendUpdatedSidebarState();
  }

  unsubscribe(): void {
    this._sidebarStateSubject.complete();
  }

  watchOnSidebarState(): Observable<ISidebarState> {
    return this._sidebarStateSubject.asObservable();
  }

  private sendUpdatedSidebarState(): void {
    this._sidebarStateSubject.next(this.state);
  }
}
