import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { interval, Subscription } from 'rxjs';
import { filter, switchMap, takeWhile, tap } from 'rxjs/operators';

import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.html',
  styleUrls: [ './loading-spinner.scss' ],
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  @Input() mode: 'spinner' | 'bar' = 'spinner';

  isLoading = false;
  loaderServiceSubscription: Subscription = new Subscription();
  progress = 0;

  constructor(
    private readonly loaderService: LoaderService,
  ) {}

  ngOnInit(): void {
    this.loaderServiceSubscription = this.loaderService
      .getIsLoadingStatus()
      .pipe(
        tap(isLoading => {
          this.isLoading = isLoading;
          this.progress = 0;
        }),
        filter(() => this.mode === 'bar'),
        switchMap(() => interval(100).pipe(
          takeWhile(() => this.progress < 90 && this.isLoading),
        )),
      )
      .subscribe(() => {
        this.progress += Math.random() * 6;
      });
  }

  ngOnDestroy(): void {
    this.loaderService.clearIgnoreListOptions();
    this.loaderServiceSubscription.unsubscribe();
  }
}
