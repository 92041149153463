export class TeamMinimalDto {
  endDate: string;
  id: number;
  name: string;
  projectId: number;
  startDate: string;

  constructor(data?: TeamMinimalDto) {
    if (data) {
      this.endDate = data.endDate;
      this.id = data.id;
      this.name = data.name;
      this.projectId = data.projectId;
      this.startDate = data.startDate;
    }
  }
}
