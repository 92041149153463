import { defaultUserImage } from '../../../../assets/images/pathToImageVariables';

import { PersonalConfigDto } from '../../dtos/personal-config.dto';
import { ProjectsDto } from '../../dtos/projects.dto';
import { TeamMinimalDto } from '../../dtos/team-minimal.dto';

import { UserMinDto } from '../../dtos/user-min.dto';

import { WorkTypeEnum } from '../../enums/work-type-enum';

import { environment } from '@config/environment';

import { UserShortestInfoDto } from './user-shortest-info.dto';

export class UserFullInfoDto {
  dateOfBirth: string;
  directManagerForUserIds: number[] = [];
  directSubordinatesByChain: number[] = [];
  emailAddress: string;
  firstName: string;
  firstWorkingDay: string;

  get fullName(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }

  hrBp: UserShortestInfoDto;
  id: number;

  get imageUrl(): string {
    return environment.showImages && this.profileUrl?.includes('cloudinary')
      ? `url(${defaultUserImage})`
      : `url(${this.profileUrl})`;
  }

  lastName: string;
  leadOfProjects: number[] = [];
  mainProjectId: number;
  mainTeamId: number;
  manageableAccountIds: number[] = [];
  manageableDeliveryBranchIds: number[] = [];
  manageableProjectIds: number[] = [];
  manageableUserIds: number[] = [];
  manager: UserMinDto;
  permissions: string[] = [];
  personalConfig: PersonalConfigDto;
  personalEmailAddress: string;
  phoneNumber: string;
  profileUrl: string;
  projects: ProjectsDto[] = [];
  teams: TeamMinimalDto[] = [];
  viewableProjectIds: number[] = [];
  viewableUserIds: number[] = [];
  workingHours: number;
  workType: WorkTypeEnum;

  constructor(data?: UserFullInfoDto) {
    if (data) {
      this.dateOfBirth = data.dateOfBirth;
      this.directManagerForUserIds = Array.isArray(data.directManagerForUserIds) ? data.directManagerForUserIds : [];
      this.directSubordinatesByChain = Array.isArray(data.directSubordinatesByChain) ? data.directSubordinatesByChain : [];
      this.emailAddress = data.emailAddress;
      this.firstName = data.firstName;
      this.firstWorkingDay = data.firstWorkingDay;
      this.hrBp = new UserShortestInfoDto(data.hrBp);
      this.id = data.id;
      this.lastName = data.lastName;
      this.leadOfProjects = Array.isArray(data.leadOfProjects) ? data.leadOfProjects : [];
      this.mainProjectId = data.mainProjectId;
      this.mainTeamId = data.mainTeamId;
      this.manageableAccountIds = Array.isArray(data.manageableAccountIds) ? data.manageableAccountIds : [];
      this.manageableDeliveryBranchIds = Array.isArray(data.manageableDeliveryBranchIds) ? data.manageableDeliveryBranchIds : [];
      this.manageableProjectIds = Array.isArray(data.manageableProjectIds) ? data.manageableProjectIds : [];
      this.manageableUserIds = Array.isArray(data.manageableUserIds) ? data.manageableUserIds : [];
      this.manager = new UserMinDto(data.manager);
      this.permissions = Array.isArray(data.permissions) ? data.permissions : [];
      this.personalConfig = new PersonalConfigDto(data.personalConfig);
      this.personalEmailAddress = data.personalEmailAddress;
      this.phoneNumber = data.phoneNumber;
      this.profileUrl = data.profileUrl;
      this.projects = Array.isArray(data.projects) ? data.projects.map(project => new ProjectsDto(project)) : [];
      this.teams = Array.isArray(data.teams) ? data.teams.map(team => new TeamMinimalDto(team)) : [];
      this.viewableProjectIds = Array.isArray(data.viewableProjectIds) ? data.viewableProjectIds : [];
      this.viewableUserIds = Array.isArray(data.viewableUserIds) ? data.viewableUserIds : [];
      this.workingHours = data.workingHours;
      this.workType = data.workType;
    }
  }
}
