import { PageableDto } from './page-config/pageable.dto';

export class RestDto<T> {
  content: T[] = [];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: PageableDto;
  size: number;
  sort: string;
  totalElements: number;
  totalPages: number;

  constructor(data?: RestDto<T>) {
    if (data) {
      this.content = data.content;
      this.first = data.first;
      this.last = data.last;
      this.number = data.number;
      this.numberOfElements = data.numberOfElements;
      this.pageable = new PageableDto(data.pageable);
      this.size = data.size;
      this.sort = data.sort;
      this.totalElements = data.totalElements;
      this.totalPages = data.totalPages;
    }
  }
}
