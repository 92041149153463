import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { NewsSearchParamsDto } from '../_shared/dtos/news-search-params.dto';
import { News } from '../_shared/dtos/news.dto';
import { NewsService } from '../_shared/services/news/news.service';

import { SidebarStateService } from './sidebar-state.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.html',
  styleUrls: [ './sidebar.scss' ],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Output() sidenavOpenedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentNewsId: string;
  defaultParams = {
    isDeleted: false,
    isDraft: false,
    to: formatDate(new Date(), 'yyyy-MM-dd 23:59:59', 'en'),
    isPreviewMode: true,
  };
  isLoading = false;
  newsList$: Observable<News[]>;
  subscriptions: Subscription = new Subscription();

  private readonly _querySubj = new BehaviorSubject<NewsSearchParamsDto>({} as NewsSearchParamsDto);

  constructor(
    private readonly newsService: NewsService,
    private readonly sidebarStateService: SidebarStateService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.sidebarStateService.watchOnSidebarState().subscribe(state => {
      const [id] = state.newsIds;

      this.currentNewsId = id;
      this.newsList$ = this._querySubj.asObservable()
        .pipe(
          tap(() => {
            this.isLoading = true;
          }),
          switchMap(params => this.newsService.getAllNews(params)
            .pipe(map(news => news.filter(item => item.id !== Number(this.currentNewsId))))),
          tap(() => {
            this.isLoading = false;
          }),
        );
    }));
    this.changeParams(this.defaultParams);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  changeParams(params: NewsSearchParamsDto): void {
    this._querySubj.next({
      ...this.defaultParams,
      ...params,
    });
  }
}
