<div
  class="sidebar"
  fxFlex
  fxLayout="column"
  fxLayoutGap="1rem">

  <img
    *ngIf="false"
    fxHide.lt-sm
    class="img-plug"
    alt="SPD Portal"
    [src]="'../../assets/images/sidebar-header.svg'">

  <app-news-list-preview
    class="news-line"
    [isSidenavOpened]="true"
    [newsList]="newsList$ | async">
  </app-news-list-preview>
</div>

<mat-icon
  *ngIf="false"
  class="hide-show-button m-2"
  matTooltip="Hide news feed"
  svgIcon="right-arrow"
  (click)="sidenavOpenedChange.emit(false)">
</mat-icon>
