import { Component } from '@angular/core';

import { SpinnerComponentWithSecondaryColor } from '../spinner-utility';

@Component({
  selector: 'app-spinner-circular-split',
  templateUrl: './spinner-circular-split.html',
  styleUrls: ['./spinner-circular-split.scss'],
})
export class SpinnerCircularSplitComponent extends SpinnerComponentWithSecondaryColor {
  get dashStyle(): Record<string, string> {
    return {
      color: this.color,
      ...
      !this.still
        ? { animation: `spinners-circular-split ${140 / this.speed}s linear infinite` }
        : {}
      ,
    };
  }

  get strokeWidth(): number {
    return 4 * (this.thickness / 100);
  }
}
