import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { MicroservicesEnum } from '../../../enums/microservices.enum';
import { RestClient } from '../rest.service';

import { AuthorizeSpdInfoDto } from './authorize-spd-info.dto';
import { AuthorizeDto } from './authorize.dto';

@Injectable()
export class AuthorizeRestService {

  constructor(private readonly http: RestClient) {}

  userTokenRefresh(refreshToken: string): Observable<AuthorizeDto> {
    return this.http.post<AuthorizeDto>(
      `/${MicroservicesEnum.TT_NEW}/api/v1/auth/token/refresh`,
      { refreshToken },
      {
        headers: { refreshToken },
      },
    );
  }

  userTokenSpdInfoRefresh(refreshToken: string): Observable<AuthorizeSpdInfoDto> {
    return this.http.post<AuthorizeSpdInfoDto>(
      `/${MicroservicesEnum.SPD_INFO}/api/v1/oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`,
      {},
    );
  }
}
