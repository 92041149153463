import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { News } from '../../_shared/dtos/news.dto';
import { NewsTypeEnum } from '../../_shared/enums/news.emun';
import { CurrentUserService } from '../../_shared/services/current-user/current-user.service';
import { UserFullInfoDto } from '../../_shared/services/user/user-full-info-dto';

@Component({
  selector: 'app-news-list-preview',
  templateUrl: './news-preview.html',
  styleUrls: [ './news-preview.scss' ],
})
export class NewsPreviewComponent implements OnInit, OnDestroy {
  @Input() isSidenavOpened = false;
  @Input() newsList: News[];

  readonly newsTypeEnum = NewsTypeEnum;
  currentParam = '';
  currentUser: UserFullInfoDto;
  subscriptions: Subscription = new Subscription();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly currentUserService: CurrentUserService,
    private readonly router: Router,
  ) {
    this.currentUser = this.currentUserService.getItem();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.route))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
      ).subscribe(route => {
        this.currentParam = route.snapshot.params['id'];

      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openDetails(newsId: number): void {
    this.router.navigate(this.currentParam ? [this.router.url.replace( this.currentParam, String(newsId))] : ['/news-feed/', newsId]);
  }
}
