import {
  OverlayRef,
  Overlay,
  OverlayPositionBuilder,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, Input, TemplateRef, HostListener, OnInit, ElementRef, ComponentRef } from '@angular/core';

import { PopupComponent } from './popup.component';

@Directive({ selector: '[appPopup]' })
export class PopupDirective implements OnInit {
  @Input('appPopup') content: string | TemplateRef<unknown>;
  @Input() isActivePopup = true;

  private overlayRef: OverlayRef;

  constructor(
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly elementRef: ElementRef,
    private readonly overlay: Overlay,
  ) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseleave')
  hide(): void {
    if (this.isActivePopup) {
      this.overlayRef.detach();
    }
  }

  @HostListener('click')
  hideOnClick(): void {
    if (this.isActivePopup) {
      this.overlayRef.detach();
    }
  }

  @HostListener('mousewheel')
  hideOnScroll(): void {
    if (this.isActivePopup) {
      this.overlayRef.detach();
    }
  }

  @HostListener('mouseenter')
  show(): void {
    if (this.isActivePopup) {
      const tooltipPortal = new ComponentPortal(PopupComponent);

      const tooltipRef: ComponentRef<PopupComponent> = this.overlayRef.attach(
        tooltipPortal,
      );

      if (typeof this.content === 'string') {
        tooltipRef.instance.text = this.content;
      } else {
        tooltipRef.instance.content = this.content;
      }
    }
  }
}
