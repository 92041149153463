export class UserShortestInfoDto {
  firstName: string;
  id: number;
  lastName: string;
  profileUrl: string;

  constructor(data?: UserShortestInfoDto) {
    if (data) {
      this.firstName = data.firstName;
      this.id = data.id;
      this.lastName = data.lastName;
      this.profileUrl = data.profileUrl;
    }
  }
}
