import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NewsFeedComponent } from './news-feed.component';

const routes: Routes = [
  {
    path: '',
    component: NewsFeedComponent,
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./news-details/news-details.module').then(m => m.NewsDetailsModule),
  },

];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class NewsFeedRouting {}
