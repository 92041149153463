export const environment = {
  api: '',
  storage: 'spd-portal',
  production: false,
  showImages: true,
  sourceUrl: 'https://dev-portal.spd.tech',
  oauthHeader: 'Basic c3lzdGVtX2RldjpwaW4=',
  enableServiceWorkers: true,
  authRedirectUrl: 'https://dev-portal.spd.tech/login',
};
