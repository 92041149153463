<svg
  *ngIf="enabled"
  fill="none"
  viewBox="0 0 66 66"
  [ngStyle]="svgStyle">

  <circle
    cx="33"
    cy="33"
    fill="none"
    r="28"
    [attr.stroke]="secondaryColor"
    [attr.stroke-width]="strokeWidth"/>

  <circle
    cx="33"
    cy="33"
    fill="none"
    r="28"
    stroke="currentColor"
    stroke-dashoffset="1"
    stroke-linecap="round"
    transform="rotate(-90 33 33)"
    [attr.stroke-width]="strokeWidth"
    [ngStyle]="dashStyle"/>
</svg>
