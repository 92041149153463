import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-new-feature-marker',
  templateUrl: './new-feature-marker.html',
  styleUrls: [ './new-feature-marker.scss' ],
})
export class NewFeatureMarkerComponent {
  @Input() color = 'orange';
}
