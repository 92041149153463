import { HttpClient, HttpHandler, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RestDto } from './rest.dto';

@Injectable()
export class RestClient extends HttpClient {

  constructor(
    handler: HttpHandler,
    private readonly notification: ToastrService,
  ) {
    super(handler);
  }

  // @ts-ignore
  delete<T>(url: string, params?: HttpParams): Observable<T> {
    return super.delete<T>(url, this.parseParams(params));
  }

  // @ts-ignore
  get<T>(url: string, params?: HttpParams = {}): Observable<T> {
    return super.get<T>(url, this.parseParams(params));
  }

  getAll<T>(
    url: string,
    params: HttpParams = {} as HttpParams,
  ): Observable<RestDto<T>> {
    return super.get<RestDto<T>>(url, this.parseParams(params))
      .pipe(map(data => new RestDto(data)));
  }

  parseParams(data: HttpParams): Record<string, unknown> {
    let params = new HttpParams();

    if (data) {
      Object.keys(data).forEach(key => {
        if (data[ key ] != null && data[ key ] !== '') {
          params = params.append(key, data[ key ]);
        }
      });
    }

    return { params } as unknown as Record<string, unknown>;
  }

  responseHandle<T>(response: Observable<T>, config: Record<string, unknown>): Observable<T> {
    return response
      .pipe(
        map(res => this.responseSuccess<T>(res, config)),
        catchError(error => this.responseError(error, config)),
      );
  }

  upload<T>(url: string, files: FileList): Observable<T> {
    return this.post<T>(url, this.parseFiles(files));
  }

  private parseFiles(files: FileList): FormData {
    const data = new FormData();

    Array.from(files).forEach(file => {
      data.append('file', file);
    });

    return data;
  }

  private responseError(error, config): Observable<never> {
    if (config[ error.status ]) {
      this.notification.error(config[ error.status ], 'Error found');
    }

    return throwError('Error found');
  }

  private responseSuccess<T>(response, config): T {
    if (config[ 200 ]) {
      this.notification.success(config[ 200 ], 'Success');
    }

    return response as T;
  }
}
