import { Component, OnDestroy, OnInit } from '@angular/core';

import { CurrentUserService } from './_shared/services/current-user/current-user.service';
import { UserFullInfoDto } from './_shared/services/user/user-full-info-dto';
import { SidebarStateService } from './sidebar/sidebar-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.html',
  styleUrls: [ './app.scss' ],
  providers: [ CurrentUserService ],
})
export class AppComponent implements OnInit, OnDestroy {
  currentUser: UserFullInfoDto;
  isSidenavOpened: boolean;
  isSmall: boolean;

  constructor(
    private readonly currentUserService: CurrentUserService,
    private readonly sidebarStateService: SidebarStateService,
  ) {
    this.currentUser = new UserFullInfoDto(this.currentUserService.getItem());
    this.currentUserService.watchStorage.subscribe(data => {
      this.currentUser = data
        ? new UserFullInfoDto(this.currentUserService.getItem())
        : new UserFullInfoDto();
    });
  }

  ngOnInit(): void {
    this.sidebarStateService.watchOnSidebarState().subscribe(data => {
      this.isSidenavOpened = data.isSidebarOpened;
    });
  }

  ngOnDestroy(): void {
    this.currentUserService.unsubscribe();
    this.sidebarStateService.unsubscribe();
  }

  sidenavStateToggle(event: boolean): void {
    this.isSidenavOpened = event;
  }
}
