import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoaderService } from '../../services/loader/loader.service';

import { LoadingSpinnerComponent } from './loading-spinner.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
  ],
  declarations: [ LoadingSpinnerComponent ],
  exports: [ LoadingSpinnerComponent ],
  providers: [ LoaderService ],
})
export class LoadingSpinnerModule {}
